@use "src/variables";

body {
  min-height: 100vh;
  min-width: 320px;
  font-family: Raleway, sans-serif;
  font-feature-settings: "lnum";
  overflow-x: hidden;
  max-width: 100%;
  color: var(--bs-dark);
  background-color: var(--bs-gray-shade);
}

.breadcrumb-container .breadcrumb-link {
  border-bottom: 2px solid var(--bs-dark-blue);
  display: inline-flex;
}

.breadcrumb-container a {
  text-decoration: none !important;
  color: var(--bs-dark-blue) !important;
}

.marketplace-items {
  text-decoration: none !important;
  color: var(--bs-dark-blue) !important;
  margin-left: 0.5rem;
  border-bottom: none;
}

.offer-heading {
  color: navy;
}

.listing-details-content {
  font-size: 18px;
}

.list-type-heading {
  color: var(--bs-midnight-blue);
}

.list-underline {
  border-bottom: 2px solid var(--bs-midnight-blue);
}

.pe-auto {
  cursor: pointer !important;
}

.tooltip-container {
  display: inline-flex;
  flex-direction: row;

  .tooltip-container-icon {
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    transition: 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);

    .tooltip-container-img {
      display: block;
      position: relative;
    }

    .tooltip-container-text {
      position: absolute;
      top: 0px;
      left: 20px;
      z-index: 10;
      padding: 6px 18px;
      border-radius: 15px;
      background-color: var(--bs-black);
      color: var(--bs-white);
      font-size: 12px;
      opacity: 0;
      pointer-events: none;
      width: fit-content;
      box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
      transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

    &:hover {
      .tooltip-container-text {
        top: 0px;
        left: 20px;
        opacity: 1;
        pointer-events: auto;
      }

      &,
      .tooltip-container-text {
        text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4);
      }
    }
  }
}

.cancel-button:hover,
.cancel-button:active {
  background-color: transparent !important;
  color: var(--bs-black) !important;
}

.data-details {
  border-bottom: 2px solid var(--bs-dark-blue);
  color: var(--bs-dark-blue);
}

.auth-container {
  position: relative;
  background-color: white;
  box-shadow: 0 0 2px var(--bs-transparent-black);
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;

  &-form {
    height: 100%;
    width: 100%;
    max-width: 420px;
    background: var(--bs-white);
    padding: 60px 0px 20px 0px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    color: var(--bs-dark-blue);
  }
}

.form-button {
  color: var(--bs-dark-blue);
  border-radius: 8px;
  min-width: 120px;
  font-weight: 600 !important;
  line-height: 24px !important;
  font-size: 16px !important;
}

.auth-container-title {
  color: var(--bs-dark-blue);
  margin-bottom: 40px;
  text-align: center;
  font-size: 36px;
}

.auth-container-redirect-path {
  text-decoration: underline;
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  color: var(--bs-dark-blue);
  cursor: pointer;
}

.fa-eye,
.fa-eye-slash {
  position: absolute;
  top: 30%;
  right: 1%;
  cursor: pointer;
}

.fa-envelope,
.fa-lock {
  position: absolute;
  top: 30%;
  left: 2%;
  cursor: pointer;
  color: lightgray;
}

.shadow-lg {
  box-shadow: 0rem 0rem 3rem var(--bs-black-with-opacity) !important;
}

.error-message {
  font-family: 'Times New Roman', Times, serif;
}

.eckard-ftm-approve {
  width: 1rem;
}

.transactions-actions {
  border-bottom: 2px solid;
}


@media (min-width: 1024px) {
  .tooltip-container-text {
    width: max-content !important;
  }
}